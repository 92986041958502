import { getAuth, 
    signInWithEmailAndPassword, 
    sendPasswordResetEmail
} from "firebase/auth";
import Error from "../languageInputComponents/Error";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Button from "@mui/material/Button";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

export default function SignupPassword() {
    const [user, setUser] = useOutletContext();
    const [userEmail, setUserEmail] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [error, setError] = useState(false);
    const [resetOpen, setResetOpen] = useState(false);
    const [resetPasswordEmail, setResetPasswordEmail] = useState();
    const navigate = useNavigate();
    const location = useLocation();

    function handleUserEmailChange(e) {
        setUserEmail(e.target.value);
    }

    function handleOpenPasswordReset() {
        setResetOpen(!resetOpen);
    }

    function handleResetChange(e) {
        setResetPasswordEmail(e.target.value);
    }

    function handleUserPasswordChange(e) {
        setUserPassword(e.target.value);
    }

    function handleSignin(e) {
        e.preventDefault();
        const auth = getAuth();
            signInWithEmailAndPassword(auth, userEmail, userPassword)
            .then((userCredential) => {
                //signed in
                const user = userCredential.user;
                setUser(user);
                //redirect user to main page
                navigate("/active-language");
            })
            .catch((error) => {
                setError(<Error messageType="error" errorMessage={`Error signing up. ${error.message}`} />);
            })
    }

    function handlePasswordReset() {
        setResetOpen(!resetOpen);
        const auth = getAuth();
        sendPasswordResetEmail(auth, resetPasswordEmail)
            .then(() => {
                setError(<Error messageType="success" errorMessage="Password reset email sent. Check spam folder if not found" />);
            })
            .catch((error) => {
                setError(<Error messageType="error" errorMessage={`There was an error. Please try again. ${error.message}`} />);
            })
    }

    return (
        <div className="signin-form">
                {resetOpen ? <Dialog onClose={handleOpenPasswordReset} open={resetOpen}>
                                <DialogTitle>Enter Your Email Address:</DialogTitle>
                                <DialogContent>
                                    <TextField
                                        autoFocus
                                        type="email"
                                        label="Email"
                                        variant="outlined"
                                        value={resetPasswordEmail}
                                        onChange={handleResetChange}
                                        required
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handlePasswordReset}>Reset Password</Button>
                                </DialogActions>
                            </Dialog> : ""}
                <Paper elevation={6} sx={{width: "50%"}}>
                    <p className="form-title"><strong>SIGN IN</strong></p>
                    {location.state !== null ? <Error messageType="warning" errorMessage={location.state.message} /> : ""}
                    {error ? error : ""}
                    <form onSubmit={handleSignin}>
                        <div className="form-details">
                            <div className="email-details">
                                <TextField 
                                    type="email" 
                                    id="email" 
                                    label="Email" 
                                    variant="outlined" 
                                    value={userEmail} 
                                    onChange={handleUserEmailChange} 
                                    sx={{width: "70%"}}
                                    required
                                />
                            </div>
                            <div className="password-details">
                                <TextField 
                                    type="password" 
                                    id="password" 
                                    label="Password" 
                                    variant="outlined" 
                                    value={userPassword} 
                                    onChange={handleUserPasswordChange} 
                                    className="password-form" 
                                    sx={{width: "70%"}}
                                    required
                                />
                            </div>
                            <div className="signin-button">
                                <Button variant="outlined" type="submit" sx={{}}>Submit</Button>
                            </div>
                            <p className="switch-sign-type">Don't Have an account? <Link to={`/sign-up`}>Create one</Link></p>
                            <p className="switch-sign-type">Forgot Password? <button style={{border: "none", cursor: "pointer", background: "none", fontSize: "inherit", fontFamily: "inherit", textDecoration: "underline", color: "#069"}} onClick={handleOpenPasswordReset}>Reset it</button></p>
                        </div>
                    </form>
                </Paper>
        </div>
    );
}