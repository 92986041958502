import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import SignupPassword from './components/Authentication/SignupPassword';
import SignInPassword from './components/Authentication/SignInPassword';
import InputContainer from './components/languageInputComponents/InputContainer';
import ErrorPage from './components/Main App Components/Error-page';
import HomePage from './components/Main App Components/HomePage';
import Account from './components/AccountComponents/Account';
import AccountSettings from "./components/DashboardComponents/AccountSettings";
import SavedWordsInterface from "./components/DashboardComponents/SavedWordsInterface";
import YoutubeTesterContainer from './components/YoutubeTesterComponents/YoutubeTesterContainer';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "active-language",
        element: <InputContainer />,
      },
      {
        path: "video-step-through",
        element: <YoutubeTesterContainer />,
      },
      {
        path: "",
        element: <HomePage />,
      },
      {
        path: "sign-up",
        element: <SignupPassword />
      },
      {
        path: "sign-in",
        element: <SignInPassword />
      },
      {
        path: "account",
        element: <Account />
      },
      {
        path: "account/saved-words",
        element: <SavedWordsInterface />
      },
      {
        path: "account/settings",
        element: <AccountSettings />
      },
    ]
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
