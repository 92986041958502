import AnimatedNumber from "react-animated-numbers";
import "../../assets/GradingComponents.css";

export default function GradeIndicator({ correctWords }) {

    function calculateAnimatedNumber() {
        let numCorrect = 0;
        for(let i = 0; i < correctWords.length; i++) {
            if(correctWords[i].isCorrect) {
                numCorrect++;
            } 
        }

        return numCorrect;
    }

    return (

        <div className="grade-indicator-container">
            <div className="grade-with-animate-wrapper">
                <div className="animated-number-container">
                    <p className="animated-number" style={{paddingRight: '8px'}}>Your Score:</p>
                    <p className="animated-number"><AnimatedNumber animateToNumber={calculateAnimatedNumber()} /></p>
                    <p className="animated-number"> / {correctWords.length}</p>
                </div>
            </div>
        </div>
    );
}