import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCQ0a7IJb13e7c_D-WAaEXJ1QWOqj854iQ",
    authDomain: "active-languages-real-23eu2.firebaseapp.com",
    projectId: "active-languages-real-23eu2",
    storageBucket: "active-languages-real-23eu2.appspot.com",
    messagingSenderId: "477571548736",
    appId: "1:477571548736:web:a7abfb0916a21b6c3e41a5",
    measurementId: "G-HKWYGVD5XN"
  };
  
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const db = getFirestore(app);

export {app,  db, analytics};