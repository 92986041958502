import ReactPlayer from "react-player";
import { useState } from "react";
import { Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

export default function YoutubePlayer({ videoURL }) {
    const [showVideo, setShowVideo] = useState(true);

    function handleVideoClick() {
        setShowVideo(!showVideo);
    }

    const videoShowDescription = `
        Click this to hide or show the YouTube video depending on your preferences.
    `;
    
    return (
        <div className="youtube-video-container">
                {showVideo ? <ReactPlayer 
                    url={videoURL} 
                    width={280} 
                    height={200} 
                    controls
                    playing={true}>
                </ReactPlayer>
                :
                ""}
                <Tooltip title={videoShowDescription} style={{padding: "3px", verticalAlign: "bottom", fontSize: "18px"}}><InfoIcon></InfoIcon></Tooltip>
                 <label htmlFor="video-checkbox" className="video-checkbox-label">Hide Youtube Video</label>
                <input type="checkbox" id="video-checkbox" className="video-checkbox" onClick={handleVideoClick} />
        </div>
    );
}