import { Avatar } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import flagImage from "../../assets/flagImage.jpg";
import { ServoiceFeedback } from "react-feedback-widget";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';

export default function HomePage() {
    const [typeText, setTypeText] = useState("Listening");
    const [scrollVisible, setScrollVisible] = useState(true);

    useEffect(() => {
        window.addEventListener("scroll", () => {setScrollVisible(false)})
        setTimeout(() => {
            setTypeText("Watching");
        }, 4000);
        setTimeout(() => {
           setTypeText("Writing");
        }, 8000)
        return () => {
            window.addEventListener("scroll", () => {setScrollVisible(false)})
        }
    }, [])

    const config = {
        servId: "cldi5xuc6000708mli2vcagnl",
        servPID: "cldi5xuc6000808mlh3nv1q4u"
    };

    return(
        <div className="home-page">
            <div className="home-page-container">
                <div className="type-effect-container">
                    <div className="static-container">
                        <p className="static-type-text">Learn by</p>
                    </div>
                    <div className="type-container">
                        <p key={Math.random()} className="type-effect">{typeText}</p>
                    </div>
                </div>
                <div className="options-row">
                        <Link to={`active-language`}><button className="home-options-button">Get Started</button></Link>
                </div>
                <ArrowCircleDownIcon sx={{color: "white", fontSize: "40px"}} className="down-arrow" style={scrollVisible ? {display: "true"} : {display: "none"}}></ArrowCircleDownIcon>
            </div>

            <div className="app-description">
                <div className="description">
                    <div className="description-number-container">
                        <Avatar sx={{bgcolor: "#2596be"}}>1</Avatar>
                    </div>
                    <p className="description-title"><strong>Choose how you want to learn</strong></p>
                    <p className="description-subtext">
                        Focused languages works by allowing anyone to use the learning resources they enjoy. Choose YouTube videos
                        or your favorite texts, with new options to choose from being added so check back regularly!
                    </p>
                </div>
                <div className="description">
                    <div className="description-number-container">
                        <Avatar sx={{bgcolor: "#c34c23"}}>2</Avatar>
                    </div>
                    <p className="description-title"><strong>Configure the test to your learning goals</strong></p>
                    <p className="description-subtext">
                        Choose from a set of options when studying. You can change the amount of blank words which will engage you more with
                        the material. It will be more challenging though! You can also enable auto grading that gives you instant feedback if 
                        the word you typed was correct or incorrect.
                    </p>
                </div>
                <div className="description">
                    <div className="description-number-container">
                        <Avatar sx={{bgcolor: "#4ac323"}}>3</Avatar>
                    </div>
                    <p className="description-title"><strong>Utilize the learning resources</strong></p>
                    <p className="description-subtext">
                        Follow along with your favorite YouTube videos or texts. Feel free to enable the word bank to show you which words 
                        are possible. You can also play the YouTube video to the side to allow you to pause and play the video for easier learning.
                    </p>
                </div>
            </div>
    
            <div className="how-works-container">
                <div className="custom-shape-divider-top-1676042209">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                    </svg>
                </div>
                <div className="bubble-container">
                    <img src={flagImage} alt="Country Flags" className="bubble-image" />
                </div>
                <div className="how-works-text">
                    <p className="how-works-title"><strong>How It Works</strong></p>
                    <p className="how-works-subtext">Focused Language works by actively engaging you with the content. 
                    Oftentimes when we are learning a language, we passively learn. Meaning we are listening, but not really learning 
                    anything. When we are actively engaged with the content, our brain is able to remember more information, and 
                    complex vocabularly and grammar structure start to make sense. The bottom line is: When we actively learn, we learn faster.</p>
                </div>
            </div>

            <footer id="footer">
                <div className="footer-container">
                <div className="custom-shape-divider-top-1676045674">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
                        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
                        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
                    </svg>
                </div>
                    <div className="contact-container">
                        <div className="contact-information">
                            <MailOutlineIcon sx={{color: "white", verticalAlign: "bottom", paddingRight: "3px"}}></MailOutlineIcon><span className="contact">focusedlanguage@gmail.com</span>
                        </div>
                        <div className="feedback-container">
                            <ServoiceFeedback config={config}>
                                <button className="feedback-button" type="button">Leave Some Feedback</button>
                            </ServoiceFeedback>
                        </div>
                    </div>
    
                </div>
            </footer>
        </div>
    );
}