import Word from "./Word";
import WordForm from "./WordForm";
import "../../assets/LanguageInput.css";
import { useEffect, useRef, useState } from "react";
import GradingContainer from "../Grading Components/GradingContainer";
import { collection, addDoc, doc, setDoc, arrayUnion, increment, where, query, getDocs, updateDoc } from "firebase/firestore"; 
import { db } from "../../FirebaseConfig";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select } from "@mui/material";
import { jsPDF } from "jspdf";

export default function TestPane({ 
    examInput, 
    autoGrading, 
    amountOfTestWords, 
    correctWords, 
    setCorrectWords, 
    wordChoice, 
    youtubeURL,
    fullYoutubeCaptionObject }) {
    const [isGrading, setIsGrading] = useState(false);
    const [testWordPosition, setTestWordPosition] = useState([]);
    const [userTargetLanguages, setUserTargetLanguages] = useState([]);
    const [savedWords, setSavedWords] = useState([]);
    const [user, setUser] = useState();
    const inputRefs = useRef([]);


    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if(user) {
                fetchTargetLanguages(user);
                setUser(true);
            }
        })
    }, []);

    useEffect(() => {
        for(let i = 0; i < examInput.length; i++) {
            if(examInput[i].isTestWord) {
                setTestWordPosition(t => [...t, i]);
                setCorrectWords(c => [...c, examInput[i]]);
            }
        }
    }, [examInput]);

    useEffect(() => {
        inputRefs.current = inputRefs.current.slice(0, amountOfTestWords);
    }, [amountOfTestWords]);

    function handleGradeClick() {
        let examInputHolder = [...correctWords];
        for(let i = 0; i < testWordPosition.length; i++) {
            if(inputRefs.current[testWordPosition[i]].value === correctWords[i].word) {
                examInputHolder[i] = {...examInputHolder[i], "isCorrect": true, "userInputValue": inputRefs.current[testWordPosition[i]].value};
            } else {
                examInputHolder[i] = {...examInputHolder[i], "isCorrect": false, "userInputValue": inputRefs.current[testWordPosition[i]].value};
            }
        }

        saveUserData(examInputHolder);
        setCorrectWords(examInputHolder);
        setIsGrading(true);
    }

    async function saveUserData(userTestResults) {
        let numberOfCorrectAnswers = 0;
        userTestResults.forEach(word => {
            if(word.isCorrect) {
                numberOfCorrectAnswers++;
            }
        });
        let userScore = numberOfCorrectAnswers * 1000;
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if(user) {
                setDoc(doc(db, "userData", user.uid), {
                    userID: user.uid,
                    correctWords: arrayUnion({date: Date.now(), score: numberOfCorrectAnswers, totalQuestions: userTestResults.length}),
                    testsSubmitted: increment(1),
                    userScore: increment(userScore)
                },
                { merge: true})
            }
        })
    }

    async function fetchTargetLanguages(user) {
        try {
            const targetLanguagesQuery = query(collection(db, "userSettings"), where("userID", "==", user.uid));
            const querySnapshot = await getDocs(targetLanguagesQuery);
            let newData = [];
            querySnapshot.forEach((doc) => {
                newData.push({id: doc.id, ...doc.data()});
            })
            setUserTargetLanguages(newData);
        } catch(e) {
            console.log(e);
        }
    }

    return (
        <>
            <Paper elevation={3} sx={{bgcolor: "hsl(36, 100%, 99%)"}}>
            <div className="test-pane">
                    {examInput.map((word, index) => {
                        return (
                            word.isTestWord ? <WordForm 
                                                key={word.id} 
                                                word={word}
                                                wordId={word.id} 
                                                wordValue={word.word} 
                                                wordTestWord={word.isTestWord} 
                                                isCorrect={word.isCorrect}
                                                autoGrading={autoGrading}
                                                setCorrectWords={setCorrectWords}
                                                correctWords={correctWords}
                                                wordChoice={wordChoice}
                                                wordBankWords={
                                                    [
                                                        examInput[Math.floor([Math.random() * (examInput.length)])]?.word,
                                                        examInput[Math.floor([Math.random() * (examInput.length)])]?.word,
                                                        examInput[Math.floor([Math.random() * (examInput.length)])]?.word,
                                                        examInput[Math.floor([Math.random() * (examInput.length)])]?.word,
                                                        word.word
                                                    ]
                                                }
                                                inputRefs={(elem) => (inputRefs.current[index] = elem)}
                                                />
                            : 
                                            <Word 
                                                key={word.id} 
                                                wordId={word.id} 
                                                wordValue={word.word} 
                                                wordTestWord={word.isTestWord} 
                                                savedWords={savedWords}
                                                setSavedWords={setSavedWords}
                                                userTargetLanguages={userTargetLanguages}
                                                youtubeURL={youtubeURL}
                                                fullYoutubeCaptionObject={fullYoutubeCaptionObject}
                                                />
                        );
                    })}
                    {autoGrading ? "" : <div className="test-pane-submit-container"><button type="submit" className="test-pane-submit-button" onClick={handleGradeClick}>Grade Answers</button></div>}
                    {isGrading ? <GradingContainer 
                                    isGrading={isGrading} 
                                    setIsGrading={setIsGrading} 
                                    correctWords={correctWords} 
                                    examInput={examInput} 
                                    savedWords={savedWords} /> 
                                : 
                    ""}
            </div>
            </Paper>
        </>
    );
}