import { useState } from "react";
import axios from "axios";
import { Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import TuneIcon from '@mui/icons-material/Tune';
import Error from "../languageInputComponents/Error";
import AdvancedOptions from "../languageInputComponents/AdvancedOptions";
import GenerateButton from "../languageInputComponents/GenerateButton";
import { v4 as uuidv4 } from 'uuid';
import "../../assets/YoutubeTesterStyles.css";


export default function YoutubeTesterInput({ 
        setYoutubeTimingObject, 
        setYoutubeWordObject,
        setYoutubeURL,
        youtubeURL,
        setWordChoices,
        setShowInput
    }) {
    const [targetLanguage, setTargetLanguage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [testFrequency, setTestFrequency] = useState(0.02);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState();
    const [url, setURL] = useState();

    function handleURLChange(e) {
        setURL(e.target.value);
    }

    function handleLanguageSelectChange(e) {
        setTargetLanguage(e.target.value);
    }

    function handleClickOpen() {
        setOpen(true);
    }

    function handleYoutubeSubmit(e) {
        e.preventDefault();
        setIsLoading(true);
        if(url.length > 29 && testFrequency > 0) {
            let tempURL = url;
            let videoID = tempURL.substring(tempURL.indexOf('=') + 1, tempURL.length);
            
            axios.get(`https://us-central1-active-languages-real-23eu2.cloudfunctions.net/getCaptions`, {
                params: {
                    youtubeID: videoID,
                    language: targetLanguage
                }
            })
            .then(captions => {

                let captionsString = captions.data;
                let youtubeArray = [];
                let uniqueNumberList = [];
                let slicedArray = [];

                for(let i = 0; i < captions.data.length; i+=3) {
                    slicedArray.push(captionsString.slice(i, i+3));
                }

                for(let i = 0; i < slicedArray.length; i++) {
                    let holderTextArray = [];
                    for(let j = 0; j < slicedArray[i].length; j++) {
                        let holderString = slicedArray[i][j].text.split(' ');
                        for(let k = 0; k < holderString.length; k++) {
                            holderString[k].replace("\n", "");
                            holderTextArray.push({"id": uuidv4(), "word": holderString[k], "isTestWord": false, "isCorrect": "", "userInputValue": ""});
                        }
                    }
                    youtubeArray.push(holderTextArray);
                }

                for(let i = 0; i < youtubeArray.length; i++) {
                    const randIndex = Math.floor(Math.random() * youtubeArray[i].length);
                    youtubeArray[i][randIndex].isTestWord = true;
                }

                let wordChoicesList = [];

                for(let i = 0; i < youtubeArray.length; i++) {
                    for(let j = 0; j < youtubeArray[i].length; j++) {
                        if(!youtubeArray[i][j].isTestWord) {
                            wordChoicesList.push(youtubeArray[i][j].word);
                        }
                    }
                }

                setYoutubeWordObject(youtubeArray);
                setYoutubeTimingObject(captions.data);
                setWordChoices(wordChoicesList);
                setYoutubeURL(url);
                setIsLoading(true);
                setShowInput(false);
                
            }).catch(error => {
                setError(<Error messageType={"error"} errorMessage={`Please try again. Also ensure that the video has subtitles, if it does not an error will occur. Error message: ${error}`} />);
            })
        } else {
            setError(<Error messageType={"warning"} errorMessage={"Please enter a valid YouTube Link"} />);
        }
    }


    const languageSelectDescription = `
        Select the language you would like the text to appear in. This would be considered your target language.
        For example, if you would like to study the words in Spanish, choose Spanish.
    `;

    return (
        <div className="youtube-input-form">
            {error ? error : ""}
            <form onSubmit={handleYoutubeSubmit}>
                <label className="youtubeURLLabel" htmlFor="urlForm">Enter Youtube URL: </label>
                <input type="text" className="youtubeURLForm" id="urlForm" onChange={handleURLChange} required />
                <select className="youtubeLanguageSelect" onChange={handleLanguageSelectChange} style={{margin: "5px", padding: "1px", display: "inline"}}>
                    <option value="">Select Language</option>
                    <option value="en">English</option>
                    <option value="es">Spanish</option>
                    <option value="fr">French</option>
                    <option value="de">German</option>
                    <option value="zh">Chinese</option>
                    <option value="pt">Portuguese</option>
                </select>
                <Tooltip title={languageSelectDescription} style={{padding: "3px", verticalAlign: "text-top", fontSize: "18px"}}><InfoIcon></InfoIcon></Tooltip>
                <GenerateButton isLoading={isLoading} setIsLoading={setIsLoading} />
            </form>
        </div>
    );
}