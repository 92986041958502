import Alert from '@mui/material/Alert';
import React from "react";

export default function Error({ errorMessage, messageType }) {

    if(messageType === "error") {
        return <Alert severity="error">Oops an error occurred. {errorMessage}</Alert>;

    } else if(messageType === "warning") {
        return <Alert severity="warning">{errorMessage}</Alert>;
    } else if(messageType === "success") {
        return <Alert severity="success">{errorMessage}</Alert>;
    }
}