import { Link, useNavigate } from "react-router-dom";
import "../../assets/MainAppComponents.css";
import { getAuth, signOut } from "firebase/auth";
import Error from "../languageInputComponents/Error";
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from "react";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';

export default function Navbar({ user, setUser }) {
    const [anchorElMobile, setAnchorElMobile] = useState(null);
    const [anchorElAvatar, setAnchorElAvatar] = useState(null);
    const [errorMessage, setErrorMessage] = useState(false);
    const navigate = useNavigate();


    const openMobile = Boolean(anchorElMobile);
    const handleClick = (event) => {
        setAnchorElMobile(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorElMobile(null);
    };

    const openAvatar = Boolean(anchorElAvatar);
    const handleClickAvatar = (event) => {
        setAnchorElAvatar(event.currentTarget);
    };
    const handleCloseAvatar = () => {
        setAnchorElAvatar(null);
    };
    
    
    function handleSignout() {
        const auth = getAuth();
        signOut(auth).then(() => {
            setUser(null);
            navigate("/");
        }).catch((error) => {
          setErrorMessage(<Error messageType="error" errorMessage={`Error signing out. ${error}`} />);
        });
    }

    return (
        <nav className="main-navbar" style={{backgroundColor: "#091e38"}}>
            {errorMessage ? errorMessage : ""}
            <div className="mobile-nav-menu">
                <MenuIcon id="mobile-button"
                    aria-controls={openMobile ? 'mobile-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMobile ? 'true' : undefined}
                    onClick={handleClick}
                    sx={{cursor: "pointer", fontSize: "2rem", color: "white"}}
                />
                <Menu
                    id="mobile-menu"
                    anchorEl={anchorElMobile}
                    open={openMobile}
                    onClose={handleClose}
                    MenuListProps={{
                    'aria-labelledby': 'mobile-button',
                    }}
                >
                    <MenuItem onClick={handleClose}>Start Learning</MenuItem>
                    {user ? 
                        <div>
                            <Link to={`/account`} style={{textDecoration: 'none', color: "black"}}><MenuItem>My Account</MenuItem></Link>
                            <MenuItem onClick={handleSignout}>Logout</MenuItem>
                        </div>
                        :
                        <div>
                             <Link to={`/sign-in`} style={{textDecoration: 'none', color: "black"}}><MenuItem onClick={handleClose}>Login</MenuItem></Link>
                        </div>
                    }
                </Menu>

                </div>
                <div className="website-logo-name">
                    <Link to={`/`} style={{textDecoration: 'none'}} className="website-title"><strong>Focused Language</strong></Link>
                </div>
                {user ?
                <div className="navbar-links-loggedin">
                    <Link to={`active-language`} style={{textDecoration: 'none'}} className="link">Start Learning</Link>
                    <Avatar id="avatar-button"
                    aria-controls={openAvatar ? 'avatar-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openAvatar ? 'true' : undefined}
                    onClick={handleClickAvatar}
                    sx={{cursor: "pointer", bgcolor: "#2479e2"}}
                    >
                    {user.email.substring(0,1)}
                    </Avatar>
                    <Menu
                    id="avatar-menu"
                    anchorEl={anchorElAvatar}
                    open={openAvatar}
                    onClose={handleCloseAvatar}
                    MenuListProps={{
                    'aria-labelledby': 'avatar-button',
                    }}
                >
                    <Link to={`/account`} style={{textDecoration: 'none', color: "black"}}>
                        <MenuItem>
                            <ListItemIcon>
                                <AccountCircleIcon></AccountCircleIcon>
                            </ListItemIcon>
                            <ListItemText>
                                My Account
                            </ListItemText>
                        </MenuItem>
                    </Link>
                    <MenuItem onClick={handleSignout}>
                        <ListItemIcon>
                            <LogoutIcon></LogoutIcon>
                        </ListItemIcon>
                        <ListItemText>
                            Sign Out
                        </ListItemText>
                    </MenuItem>
                </Menu>

                </div>
                :
                <div className="navbar-links">
                    <Link to={`active-language`} style={{textDecoration: 'none'}} className="link">Start Learning</Link>
                    <Link to={`video-step-through`} style={{textDecoration: 'none'}} className="link">Paced Learning</Link>
                    {!user ? <>
                        <Link to={`sign-in`} style={{textDecoration: 'none'}} className="link">Login</Link>
                    </>
                    :
                    <button onClick={handleSignout}>Sign Out</button>
                    }
                </div>
                }
                
            </nav>
            
    );
}