import { Checkbox, Tooltip } from "@mui/material";
import {useState} from "react";
import AdvancedOptions from "./AdvancedOptions";
import InfoIcon from '@mui/icons-material/Info';
import FrequencySelector from "./FrequencySelector";
import TuneIcon from '@mui/icons-material/Tune';


export default function LanguageInput({ setIsTextInput, setExamInput, autoGrading, setAutoGrading, setIsSelectedGenerate, setWordChoice, wordChoice }) {
    const [inputValue, setInputValue] = useState("");
    const [testFrequency, setTestFrequency] = useState(0.02);
    const [open, setOpen] = useState(false);

    function handleChange(e) {
        setInputValue(e.target.value);
    }

    function handleGradingClick() {
        setAutoGrading(!autoGrading);
    }

    function handleWordChoiceClick() {
        setWordChoice(!wordChoice);
    }

    function handleGenerateClick() {
        setIsSelectedGenerate(true);
    }

    function handleClickOpen() {
        setOpen(!open);
    }

    function processInput(e) {
        const uniqueNumberList = [];

        e.preventDefault();
        const inputArray = inputValue.split(' ');

        const processedInput = [];
        for(let i = 0; i < inputArray.length; i++) {
            processedInput.push({"id": i, "word": inputArray[i], "isTestWord": false, "isCorrect": "", "userInputValue": ""});
        }

        const numberOfTestWords = Math.floor(processedInput.length * testFrequency);
        //set number of test words
        //setAmountOfTestWords(numberOfTestWords);

        for(let i = 0; i < numberOfTestWords; i++) {
            const randIndex = Math.floor(Math.random() * processedInput.length);
            //check if the word has already been used or if it contains any punctuation
            if(!uniqueNumberList.includes(randIndex) && /[,.?]/.test(processedInput[randIndex].word) === false) {
                processedInput[randIndex].isTestWord = true;
                uniqueNumberList.push(randIndex);
            } else {
                i--;
            }
        }
        setExamInput(processedInput);
        setIsTextInput(true);
        setInputValue("");
    }

    const autoGradingDescription = `
        Check this box if you would like to enable autograding. Autograding is a feature that automatically tells you
        if your answer is correct when you type it in. If you disable autograding, you will receive grading feedback at the 
        end when you click submit.
    `;
    
    return (
        <div className="language-input">
            <form onSubmit={processInput}>
                <textarea type="text" id="language-input" onChange={handleChange} value={inputValue} cols={53} rows={6} placeholder="Input Text Here"></textarea>
                <button type="button" className="configure-options-button" onClick={handleClickOpen}><TuneIcon style={{fontSize: "1rem", paddingRight: "3px", verticalAlign: "bottom"}}></TuneIcon>Configure Options</button>
                <AdvancedOptions open={open} setOpen={setOpen} setTestFrequency={setTestFrequency} handleGradingClick={handleGradingClick} handleWordChoiceClick={handleWordChoiceClick} />
                <button type="submit" className="language-input-button" onClick={handleGenerateClick}>Generate</button>
            </form>
        </div>
    );
}