import {FaArrowRight} from "react-icons/fa";

export default function GradingList({ correctWords, examInput }) {

    return (
        <div className="grading-list-container">
            {examInput.map((word, index) => {
                if(word.isTestWord) {
                    if((correctWords.find(cw => cw.id === word.id)).isCorrect) {
                        return <div key={index} className="grading-word-container-correct-indicator" style={{color: "green" }}>{word.word} </div>;
                    } else {
                        return <div key={index} className="grading-word-container-indicator" style={{ color: "red"}}><span className="incorrect-word-grade">{(correctWords.find(cw => cw.id === word.id)).userInputValue}</span> <span className="correct-word-grade"><FaArrowRight />  {word.word}</span> </div>;
                    }
                } else {
                    return <div key={index} className="grading-word-container">{word.word} </div>;
                }
            })}
            </div>

    );
}