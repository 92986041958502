import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Tooltip } from "@mui/material";
import { useState } from "react";
import FrequencySelector from "./FrequencySelector";
import InfoIcon from '@mui/icons-material/Info';


export default function AdvancedOptions({ setTestFrequency, handleGradingClick, setOpen, open, handleWordChoiceClick}) {

    function handleClose() {
        setOpen(!open);
    }

    const autoGradingDescription = `
        Check this box if you would like to enable autograding. Autograding is a feature that automatically tells you
        if your answer is correct when you type it in. If you disable autograding, you will receive grading feedback at the 
        end when you click submit.
    `;

    const wordChoicesDescription = `
        Check this box if you would not like to have answer choices appear when you are typing in a word. If you leave this unchecked
        then word choices will appear for each blank word to assist you.
    `;

    return (
        <div className="advanced-options-container">
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Configure Test</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        Specify how many blank words you will be tested on. Additionally you may enable auto-grading.
                    </DialogContentText>
                    <FrequencySelector setTestFrequency={setTestFrequency} />
                    <Tooltip title={autoGradingDescription} style={{padding: "3px", verticalAlign: "text-top", fontSize: "18px"}}><InfoIcon></InfoIcon></Tooltip>
                    <label htmlFor="grading-type-checkbox" className="grading-type-checkbox-label">Enable Auto Grading</label>
                    <Checkbox type="checkbox" id="grading-type-checkbox" className="grading-type-checkbox" onClick={handleGradingClick} />
                    <Tooltip title={wordChoicesDescription} style={{padding: "3px", verticalAlign: "text-top", fontSize: "18px"}}><InfoIcon></InfoIcon></Tooltip>
                    <label htmlFor="word-choices" className="word-choices-label">Disable Word Choices</label>
                    <Checkbox type="checkbox" id="word-choices" className="word-choices" onClick={handleWordChoiceClick} />
                    </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Done</Button>
                </DialogActions>
            </Dialog>
      </div>
    );
}