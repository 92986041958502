import ReplayIcon from '@mui/icons-material/Replay';

export default function YoutubeTesterRewind({ playerRef, setIsPlaying, youtubeTimingObject, videoTiming }) {

    function rewindToLastSection() {
        let seekToTime = youtubeTimingObject[videoTiming].start;
        playerRef.current.seekTo(seekToTime, "seconds");
        setIsPlaying(true);
    }

    return (
        <button class="rewind-button" onClick={rewindToLastSection}><ReplayIcon /></button>
    );
}