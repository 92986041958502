import { Alert, Button, Card, CardActions, CardHeader, Checkbox, Dialog, DialogContent, DialogContentText, Grid, Typography } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CardContent from '@mui/material/CardContent';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SavedWordsContainer from "./SavedWordsContainer";
import FavoriteIcon from '@mui/icons-material/Favorite';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { useState } from "react";
import { doc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from "../../FirebaseConfig";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import SavedWord from "./SavedWord";


export default function SavedWordsCard({ videoID, dateSaved, words, isFavorite, id, fetchSavedWords, user }) {
    const [isShowingAllWords, setisShowingAllWords] = useState(false);
    const [isMarkingAsLearned, setIsMarkingAsLearned] = useState(false);
    const [learnedWordList, setLearnedWordList] = useState([]);

    function handleShowAllWordsClick() {
        setisShowingAllWords(true);
    }

    function handleShowAllWordsClose() {
        setisShowingAllWords(false);
    }

    async function handleEditLearnedWords() {
        const newSavedWordRef = doc(db, "savedWords", id);
        const filteredArray = words.filter((word) => word.includes(learnedWordList))
        /*await updateDoc(newSavedWordRef, {
            words: [...words, learnedWordList]
        });*/
        setIsMarkingAsLearned(!isMarkingAsLearned);
    }

    async function handleFavoriteClick() {
        const newSavedWordRef = doc(db, "savedWords", id);
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if(user) {
                updateDoc(newSavedWordRef, {
                    isFavorite: !isFavorite
                });
                fetchSavedWords(user);
            }
        })        
    }

    async function handleDeleteClick() {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if(user) {
                deleteDoc(doc(db, "savedWords", id));
                fetchSavedWords(user);
            }
        })
    }

    return (
        <div className="saved-words-card">
            <Dialog
                open={isShowingAllWords}
                fullWidth
                onClose={handleShowAllWordsClose}
                aria-labelledby="show-all-words"
                aria-describedby="show-all-words"
            >
                <DialogTitle>
                {isMarkingAsLearned ? <Alert severity="info" sx={{marginBottom: "17px"}}>Click on the word to mark it as learned</Alert> : ""}
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            All Words In This List
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {words.map((word, i) => {
                            return <Grid item xs={4} key={i}>
                                <SavedWord 
                                    wordID={id}
                                    savedWordValue={word} 
                                />
                        </Grid>
                        })}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleShowAllWordsClose}>Close</Button>
                </DialogActions>
            </Dialog>
            <Card sx={{maxHeight: "330px", fontSize: "15px"}}>
                <CardHeader
                    action={
                    <IconButton aria-label="options" onClick={handleDeleteClick}>
                        <DeleteForeverIcon />
                    </IconButton>
                }
                subheader={videoID}
                />
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        {dateSaved}
                    </Typography>

                    <div className="words-container">
                        <SavedWordsContainer words={words} />
                    </div>    
                </CardContent>
                <CardActions disableSpacing>
                    <IconButton onClick={handleFavoriteClick} aria-label="add to favorites">
                        <FavoriteIcon sx={isFavorite ? {color: "red"} : ""}/>
                    </IconButton>
                    <Button variant="outlined" onClick={handleShowAllWordsClick}>Show All Words</Button>
                </CardActions>
            </Card>
        </div>
    );
}