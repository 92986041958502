import { Divider, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Typography } from "@mui/material";
import { Link, useOutletContext } from "react-router-dom";
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';

export default function AccountSidebar() {
    const [user, setUser] = useOutletContext();

    return (
        <Paper elevation={1} sx={{height: "100%"}}>
            <MenuList>
                    <Typography variant="subtitle1" sx={{ textAlign: "center", padding: "10px"}}>{user?.email}</Typography>
                <Divider />
                <MenuItem sx={{marginBottom: "5px", marginTop: "5px"}}>
                    <ListItemIcon>
                        <HomeIcon></HomeIcon>
                    </ListItemIcon>
                    <Link to={"/account"} style={{textDecoration: 'none', color: "black"}}><ListItemText>Account Home</ListItemText></Link>
                </MenuItem>
                <MenuItem sx={{marginBottom: "5px", marginTop: "5px"}}>
                    <ListItemIcon>
                        <FolderSpecialIcon></FolderSpecialIcon>
                    </ListItemIcon>
                    <Link to={"/account/saved-words"} style={{textDecoration: 'none', color: "black"}}><ListItemText>Saved Words</ListItemText></Link>
                </MenuItem>
                <MenuItem sx={{marginBottom: "5px", marginTop: "5px"}}>
                    <ListItemIcon>
                        <SettingsIcon></SettingsIcon>
                    </ListItemIcon>
                    <Link to={"/account/settings"} style={{textDecoration: 'none', color: "black"}}><ListItemText>Account Settings</ListItemText></Link>
                </MenuItem>
            </MenuList>
        </Paper>
    );
}