import { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { Stack } from '@mui/material';
import "../../assets/YoutubeTesterStyles.css";


export default function YoutubeTesterAnswers({ objectCounterRef,
     youtubeWordObject, 
     handleAnswerSubmit, 
     wordChoices, 
     videoCounter, 
     setVideoCounter,
     setIsPlaying,
     isPlaying,
     setVideoTiming
     }) {

    const [answerChoicesArray, setAnswerChoicesArray] = useState([]);
    const [wrongAnswerClick, setWrongAnswerClick] = useState();

    useEffect(() => {
        let answerArray = [];
        for(let i = 0; i < youtubeWordObject[videoCounter].length; i++) {
            if(youtubeWordObject[videoCounter][i].isTestWord) {
                answerArray.push(youtubeWordObject[videoCounter][i]);
            }
        }
        for(let i = 0; i < 9; i++) {
            answerArray.push({id: uuidv4(), isCorrect: "", isTestWord: false, userInputValue: "", word: wordChoices[Math.floor(Math.random() * (wordChoices.length))]});
        }
        for (var i = answerArray.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = answerArray[i];
            answerArray[i] = answerArray[j];
            answerArray[j] = temp;
        }

        setAnswerChoicesArray(answerArray);
    }, [videoCounter])

    function increaseVideoCounter(e) {
        setVideoCounter((prevState) => prevState + 1);
        setVideoTiming((prevState) => prevState + 3);
        setIsPlaying(true);
    }

    return (
        <div>
            <Stack direction="row" spacing={1}>
            {answerChoicesArray.map((words, i) => {
                return (
                    <button key={i} class="answer-buttons" onClick={words.isTestWord ? increaseVideoCounter : ""} disabled={isPlaying}>{words.word}</button>
                )
            })}
            </Stack>
        </div>
    );
}