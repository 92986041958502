import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import Error from "../languageInputComponents/Error";
import { useState } from "react";
import "../../assets/Authentication.css";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Button from "@mui/material/Button";
import { Chip, Grid, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import { Box } from "@mui/system";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../FirebaseConfig";


export default function SignupPassword() {
    const [user, setUser] = useOutletContext();
    const [userEmail, setUserEmail] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [error, setError] = useState(false);
    const [userNativeLanguage, setUserNativeLanguage] = useState();
    const [userTargetLanguages, setUserTargetLanguage] = useState([]);
    const navigate = useNavigate();
    let languageNames = new Intl.DisplayNames(["en"], { type: "language" });

    function handleUserEmailChange(e) {
        setUserEmail(e.target.value);
    }

    function handleUserPasswordChange(e) {
        setUserPassword(e.target.value);
    }

    function handleUserNativeLanguageChange(e) {
        setUserNativeLanguage(e.target.value);
    }

    function handleUserTargetLanguageChange(e) {
        const {
            target: { value },
          } = e;
          setUserTargetLanguage(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
          );
    }

    function handleSignup(e) {
        e.preventDefault();
        const auth = getAuth();
        createUserWithEmailAndPassword(auth, userEmail, userPassword)
        .then((userCredential) => {
            //signed in
            const user = userCredential.user;
            setUser(user);
            try {
                const docRef = addDoc(collection(db, "userSettings"), {
                    userID: user.uid,
                    nativeLanguage: userNativeLanguage,
                    targetLanguages: userTargetLanguages
                });
            } catch (e) {
                console.error("Error adding document: " + e);
            }
            //redirect user to main page
            navigate("/active-language");
        })
        .catch((error) => {
            setError(<Error messageType="error" errorMessage={`Error signing up. ${error.message}`} />);
        })
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
    PaperProps: {
        style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        },
    },
    };

    return (
        <div className="signup-form">
        <Paper elevation={6} sx={{width: "50%"}}>
            <p className="form-title"><strong>CREATE AN ACCOUNT</strong></p>
            {error ? error : ""}
            <form onSubmit={handleSignup}>
                <div className="form-details">
                    <div className="email-details">
                        <TextField 
                            type="email" 
                            id="email" 
                            label="Email" 
                            variant="outlined" 
                            value={userEmail} 
                            onChange={handleUserEmailChange} 
                            sx={{width: "70%"}}
                            required
                        />
                    </div>
                    <div className="password-details">
                        <TextField 
                            type="password" 
                            id="password" 
                            label="Password" 
                            variant="outlined" 
                            value={userPassword} 
                            onChange={handleUserPasswordChange} 
                            className="password-form" 
                            sx={{width: "70%"}}
                            required
                        />
                    </div>
                        <InputLabel id="native-language-select-label">What is your native language?</InputLabel>
                        <Select
                            labelId="native-language-select-label"
                            id="native-language-select"
                            value={userNativeLanguage}
                            onChange={handleUserNativeLanguageChange}
                            sx={{width: "70%", margin: "0 auto"}}
                            required
                        >
                            <MenuItem value={"en"}>English</MenuItem>
                            <MenuItem value={"es"}>Spanish</MenuItem>
                            <MenuItem value={"fr"}>French</MenuItem>
                            <MenuItem value={"de"}>German</MenuItem>
                            <MenuItem value={"zh"}>Chinese</MenuItem>
                            <MenuItem value={"pt"}>Portuguese</MenuItem>
                        </Select>

                        <InputLabel id="target-language-select-label">What Languages do you want to learn?</InputLabel>
                        <Select
                        labelId="target-language-select-label"
                        id="target-language-select"
                        multiple
                        sx={{width: "70%", margin: "0 auto"}}
                        value={userTargetLanguages}
                        required
                        onChange={handleUserTargetLanguageChange}
                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={languageNames.of(value)} />
                            ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                        >
                            <MenuItem value={"en"}>English</MenuItem>
                            <MenuItem value={"es"}>Spanish</MenuItem>
                            <MenuItem value={"fr"}>French</MenuItem>
                            <MenuItem value={"de"}>German</MenuItem>
                            <MenuItem value={"zh"}>Chinese</MenuItem>
                            <MenuItem value={"pt"}>Portuguese</MenuItem>
                        </Select>
                    <div className="signin-button">
                        <Button variant="outlined" type="submit" sx={{}}>Submit</Button>
                    </div>
                    <p className="switch-sign-type">Already have an account? <Link to={`/sign-in`}>Sign In</Link></p>
                </div>
            </form>
            </Paper>
        </div>
    );
}