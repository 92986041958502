import AccountSidebar from "./AccountSidebar";
import "../../assets/AccountStyles.css";
import { collection, getDocs, where, query } from "firebase/firestore"; 
import { db } from "../../FirebaseConfig";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { Card, CardContent, CircularProgress, Grid, LinearProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import DataChart from "../DashboardComponents/DataChart";
import UserPieChart from "../DashboardComponents/UserPieChart";
import UserRadialGraph from "../DashboardComponents/UserRadialGraph";
import * as dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";

export default function Account({ }) {
    const [userData, setUserData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    
    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if(user) {
                fetchUserData(user);
            } else {
                navigate("/sign-in");
            }
        })
    }, []);

    async function fetchUserData(user) {
        setIsLoading(true);
        try {
            const userDataQuery = query(collection(db, "userData"), where("userID", "==", user?.uid));
            const querySnapshot = await getDocs(userDataQuery);
            let newData = [];
            querySnapshot.forEach((doc) => {
                newData.push({id: doc.id, ...doc.data()});
            })
            setUserData(newData);
        } catch(e) {
            console.log(e);
        }
        setIsLoading(false);
    }

    function calculateCorrectPercentage() {
        let totalQuestion = 0;
        let totalCorrect = 0;
        userData[0].correctWords.forEach(test => {
            if(dayjs(test.date).isAfter(dayjs().startOf("day"))) {
                totalQuestion += test.totalQuestions;
                totalCorrect += test.score;
            }
        })
        if(totalQuestion !== 0 && totalCorrect !== 0) {
            let testPercentage = Math.floor((totalCorrect / totalQuestion) * 100);
            return testPercentage + "%";
        } else {
            return <p>No tests today</p>
        }
        
    }

        return (
            <div className="account-container">
                <div className="sidebar">
                    <AccountSidebar />
                </div>
                <div className="main-account-display">
                    <Grid container spacing={2} sx={{marginTop: 3}}>
                        <Grid item xs={3}>
    
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{width: 110, marginRight: 1}}>
                                <Typography variant="h6" color="text.secondary">Level {userData.length !== 0 ? Math.floor(userData[0].userScore / 10000) : ""}</Typography>
                            </Box>
                            <Box sx={{ width: '100%', mr: 1 }}>
                                <LinearProgress variant="determinate" value={userData.length !== 0 ? (userData[0].userScore / 100) : 0} sx={{height: 19, borderRadius: 5}}/>
                            </Box>
                            <Box sx={{ minWidth: 100 }}>
                                <Typography variant="body2" color="text.secondary">{`${userData.length !== 0 ? userData[0].userScore : 0} / 10000`}</Typography>
                            </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            
                        </Grid>
                        <Grid item xs={4} sx={{marginTop: 2}}>
                            <Card sx={{bgcolor: "#091e38"}}>
                                <CardContent>
                                    <Typography variant="h5" component="div" sx={{color: "#e6e6e6"}}>
                                        All Tests Taken
                                    </Typography>
                                    {isLoading ? <CircularProgress /> :
                                    <Typography variant="h4" sx={{textAlign: "center", paddingTop: "20px", color: "white"}}>
                                        {userData.length !== 0 ? `${userData[0].testsSubmitted} Tests` : <p>No Data</p>}
                                    </Typography>
                                    }
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={4} sx={{marginTop: 2}}>
                            <Card sx={{bgcolor: "#091e38"}}>
                                <CardContent>
                                    <Typography variant="h5" component="div" sx={{color: "#e6e6e6"}}>
                                        Your Total Score
                                    </Typography>
                                    {isLoading ? <CircularProgress /> :
                                    <Typography variant="h4" sx={{textAlign: "center", paddingTop: "20px", color: "white"}}>
                                        {userData.length !== 0 ? userData[0].userScore : <p>No Data</p>}
                                    </Typography>
                                    }
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={4} sx={{marginTop: 2}}>
                            <Card sx={{bgcolor: "#091e38"}}>
                                <CardContent>
                                    <Typography variant="h5" component="div" sx={{color: "#e6e6e6"}}>
                                        Correct Today
                                    </Typography>
                                    {isLoading ? <CircularProgress /> :
                                    <Typography variant="h4" sx={{textAlign: "center", paddingTop: "20px", color: "white"}}>
                                        {userData.length !== 0 ? calculateCorrectPercentage() : <p>No Data</p>}
                                    </Typography>
                                    }
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
}