import "../../assets/GradingComponents.css";
import Backdrop from '@mui/material/Backdrop';
import { Paper } from "@mui/material";
import GradingList from "./GradingList";
import GradeIndicator from "./GradeIndicator";

export default function GradingContainer({ isGrading, setIsGrading, correctWords, examInput }) {

    function handleGradingContainerClose() {
        window.location.reload();
        setIsGrading(false);
    }

    return (
        <div className="grading-container">
            <Backdrop
                open={isGrading}
            >
                <Paper className="grading-container-paper" elevation={2}>
                    <GradeIndicator correctWords={correctWords} />
                    <GradingList correctWords={correctWords} examInput={examInput}  />
                    <button className="grading-container-close" onClick={handleGradingContainerClose}>Close</button>
                </Paper>
            </Backdrop>
        </div>
    );  
}