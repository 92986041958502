import { Button, CircularProgress, ClickAwayListener, Grid, IconButton, Snackbar, Typography } from "@mui/material";
import { useState, forwardRef } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import MuiAlert from '@mui/material/Alert';
import "../../assets/LanguageInput.css";
import axios from "axios";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { getAuth } from "firebase/auth";
import { collection, addDoc, doc, where, query, getDocs, updateDoc } from "firebase/firestore"; 
import { db } from "../../FirebaseConfig";


const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

export default function Word({ wordValue, setSavedWords, userTargetLanguages, youtubeURL, fullYoutubeCaptionObject }) {
    const [open, setOpen] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const navigate = useNavigate();
    const [user, setUser] = useOutletContext();
    const [translatedText, setTranslatedText] = useState("");
    
  function handleTranslationRequest() {
    if(user) {
      handleTooltipOpen();
      console.log(userTargetLanguages[0].nativeLanguage);
      axios.get(`https://us-central1-active-languages-real-23eu2.cloudfunctions.net/getWordTranslation`, {
        params: {
            textWord: wordValue,
            targetLanguage: userTargetLanguages[0].nativeLanguage
        }
      }).then((responseData) => {
        setTranslatedText(responseData.data.translations[0].text);
      }).catch((error) => {
        console.log(error);
      })
    } 
  }

    const handleClick = () => {
      setOpen(true);
    };
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };

    const handleTooltipClose = () => {
      setTooltipOpen(false);
    };
  
    const handleTooltipOpen = () => {
      setTooltipOpen(true);
    };

    async function handleSaveUserWords() {
      if(user) {
        const auth = getAuth();
        const user = auth.currentUser;
        const savedWordsQuery = query(collection(db, "savedWords"), where("userID", "==", user?.uid));
        const querySnapshot = await getDocs(savedWordsQuery);
        let newData = [];
        querySnapshot.forEach((doc) => {
            newData.push({id: doc.id, ...doc.data()});
        })
        if(newData.some(el => el.videoID === youtubeURL)) {
            let dataObject = {};
            newData.forEach(el => {
                if(el.videoID === youtubeURL) {
                    dataObject = el;
                }
            })
            const finalUpdatedList = [...dataObject.words, {wordValue: wordValue, isLearned: false, translation: translatedText }];
            const newWordListRef = doc(db, "savedWords", dataObject.id);
            await updateDoc(newWordListRef, {
                words: finalUpdatedList
            })

            handleClick();
        } else {
            const exampleSentence = findSentenceInCaptions();
            try {
                await addDoc(collection(db, "savedWords"), {
                    userID: user.uid,
                    saveDate: Date.now(),
                    videoID: youtubeURL,
                    words: {wordValue: wordValue, isLearned: false, translation: translatedText }
                });
                handleClick();
            } catch (e) {
                console.error("Error adding document: " + e);
            }
        }
      } else {
        navigate("/sign-in", {state: {message: "Please sign in to use this feature"}});
      }
  }

  function findSentenceInCaptions() {
      fullYoutubeCaptionObject.forEach(caption => {
        if(caption.text.includes(wordValue)) {
          return caption.text;
        } else {
          return "";
        }
      })
  }

    return (
        <div className="word-container">
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <HtmlTooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={tooltipOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={
                  <>
                    <Typography>{wordValue} = {translatedText !== "" ? translatedText : <CircularProgress />}</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <Button startIcon={<SaveAltIcon />} onClick={handleSaveUserWords}>Save Word</Button>
                      </Grid>
                      <Grid item xs={4}>
                        <IconButton onClick={handleTooltipClose}>
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </>
                }
              >
                <span onClick={handleTranslationRequest} style={user ? {cursor: "pointer"} : {}}>{wordValue}</span>  
              </HtmlTooltip>
            </div>
          </ClickAwayListener>



          <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                {`Added Word: ${wordValue}. You must complete the test for words to save.`}
                </Alert>
            </Snackbar>        
        </div>
    );
}