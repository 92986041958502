

export default function SavedWordsContainer({ words }) {

    return (
        <>
            {words.slice(0, 9).map((word, i) => {
                return <div key={i} className="saved-word-cell">
                    <p>{word.wordValue}</p>
                </div>
            })}
        </>
    );
}