import {useState} from "react";
import { Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

export default function FrequencySelector({ testFrequency, setTestFrequency, examInput }) {
    const [customSelect, setCustomSelect] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [customSelectValue, setCustomSelectValue] = useState();

    function handleCustomSelect(e) {
        if(e.target.value === "custom-percent") {
            setCustomSelect(true);
        } else {
            setTestFrequency(e.target.value);
            setCustomSelect(false);
        }
    }

    function handleCustomInputChange(e) {
        setCustomSelectValue(e.target.value);
    }

    function handleCustomInput() {
        if(customSelectValue < 0 || customSelectValue > 100) {
            setErrorMessage(!errorMessage);
        } else {
            setErrorMessage(false);
            const cleanedInput = customSelectValue / 100;
            setTestFrequency(cleanedInput);
        }
    }

    const frequencyDescription = `
        Select the percentage of words that you want to be blank. You can also input a custom amount if you would like.
        Note that a higher percentage will create more blank words making it more difficult.
    `;

    return (
            <div className="frequency-change">
                <Tooltip title={frequencyDescription} style={{padding: "3px", verticalAlign: "bottom", fontSize: "18px"}}><InfoIcon></InfoIcon></Tooltip>
                <label className="select-label" htmlFor="frequency-select">Select Amount of Test Words: </label>
                <select className="frequency-select" id="frequency-select" onChange={handleCustomSelect}>
                        <option value={0}>Select Amount</option>
                        <option value={0.02}>Easy - 2% of words</option>
                        <option value={0.05}>Moderate - 5% of words</option>
                        <option value={0.1}>Hard - 10% of words</option>
                        <option value="custom-percent">Choose custom percent</option>
                </select>
                {customSelect && <div className="custom-input"><input type="number" id="customSelectForm" placeholder="Enter percent" min="0" step="1" max="100" required pattern="\d+" value={customSelectValue} onChange={handleCustomInputChange} /> 
                            <button className="custom-submit-button" onClick={handleCustomInput}>Save</button>
                            </div>
                            }
                {errorMessage && <p>Please enter valid input - must be greater than 0 less than 100</p>}
            </div>
    );
}