import ReactPlayer from "react-player";
import { useState, useRef, useEffect } from "react";
import YoutubeTesterWordDisplay from "./YoutubeTesterWordDisplay";
import YoutubeTesterAnswers from "./YoutubeTesterAnswers";
import "../../assets/YoutubeTesterStyles.css";
import Grid from '@mui/material/Grid';
import YoutubeTesterRewind from "./YoutubeTesterRewind";
import YoutubeTesterProgressBar from "./YoutubeTesterProgressBar";


export default function YoutubePlayer({ videoURL, youtubeTimingObject, youtubeWordObject, wordChoices }) {
    const [isPlaying, setIsPlaying] = useState(true);
    const playerRef = useRef();
    const [videoCounter, setVideoCounter] = useState(0);
    const [videoTiming, setVideoTiming] = useState(0);
    let intervalID;
    let objectCounterRef = useRef(0);

    function handlePause() {
        setIsPlaying(false);
    }

    function controlPlayback() {
        intervalID = setInterval(checkPlaybackTime, 250);
    }

    function checkPlaybackTime() {
        if(youtubeTimingObject[videoTiming + 2].start) {
            if(parseFloat(youtubeTimingObject[videoTiming + 2].start) + parseFloat(youtubeTimingObject[videoTiming + 2].dur) < playerRef.current.getCurrentTime()) {
                handlePause();
                clearInterval(intervalID);
            }
        } else {
            handlePause();
            clearInterval(intervalID);
        }
    }

    return (
        <div className="youtube-video-container">
            <YoutubeTesterProgressBar videoCounter={videoCounter} youtubeWordObject={youtubeWordObject} videoTiming={videoTiming} />
            <Grid container spacing={2}>
                <Grid item xs={2}></Grid>
                <Grid item xs={8}>
                    <ReactPlayer 
                    class="react-player"
                    ref={playerRef}
                    url={videoURL} 
                    width={400} 
                    height={300} 
                    playing={isPlaying}
                    onPlay={controlPlayback}
                />

                {youtubeWordObject ? <YoutubeTesterWordDisplay objectCounterRef={objectCounterRef} youtubeWordObject={youtubeWordObject} videoCounter={videoCounter} /> : ""}
                <div className="answer-rewind">
                    {youtubeWordObject ? <YoutubeTesterAnswers 
                                            objectCounterRef={objectCounterRef} 
                                            youtubeWordObject={youtubeWordObject} 
                                            wordChoices={wordChoices} 
                                            videoCounter={videoCounter}
                                            setVideoCounter={setVideoCounter}
                                            setIsPlaying={setIsPlaying}
                                            isPlaying={isPlaying}
                                            setVideoTiming={setVideoTiming}
                    /> : ""}
                    {!isPlaying ? <YoutubeTesterRewind youtubeTimingObject={youtubeTimingObject} setIsPlaying={setIsPlaying} playerRef={playerRef} videoTiming={videoTiming} /> : ""}
                </div>
                </Grid>
                <Grid item xs={2}></Grid>
            </Grid>
            
        </div>
    );
}