import { useState } from "react";
import YoutubeTesterInput from "./YoutubeTesterInput";
import YoutubeTesterPlayer from "./YoutubeTesterPlayer";


export default function YoutubeTesterContainer() {
    const [youtubeWordObject, setYoutubeWordObject] = useState();
    const [youtubeTimingObject, setYoutubeTimingObject] = useState();
    const [youtubeURL, setYoutubeURL] = useState();
    const [wordChoices, setWordChoices] = useState();
    const [showInput, setShowInput] = useState(true);

    return (
        <div className="youtube-tester-container">
            {showInput ? <YoutubeTesterInput 
                setYoutubeTimingObject={setYoutubeTimingObject} 
                setYoutubeURL={setYoutubeURL} 
                setYoutubeWordObject={setYoutubeWordObject} 
                youtubeURL={youtubeURL}
                setWordChoices={setWordChoices}
                setShowInput={setShowInput}
            /> : ""}
            {youtubeWordObject !== null ? <YoutubeTesterPlayer 
                videoURL={youtubeURL} 
                youtubeWordObject={youtubeWordObject} 
                youtubeTimingObject={youtubeTimingObject} 
                wordChoices={wordChoices} /> 
                : 
            ""}
        </div>
    );
}