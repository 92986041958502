import { useEffect, useState } from "react";
import "../../assets/AccountStyles.css";
import AccountSidebar from "../AccountComponents/AccountSidebar";
import { db } from "../../FirebaseConfig";
import { Button, Card, CardContent, CardHeader, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, InputLabel, MenuItem, OutlinedInput, Select, Typography } from "@mui/material";
import { collection, getDocs, query, where, doc, updateDoc } from "firebase/firestore";
import { getAuth, onAuthStateChanged, deleteUser } from "firebase/auth";
import { useNavigate, useOutletContext } from "react-router-dom";
import Error from "../languageInputComponents/Error";
import { Box } from "@mui/system";
import EditIcon from '@mui/icons-material/Edit';

export default function AccountSettings() {
    const [userSettings, setUserSettings] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState();
    const [error, setError] = useState();
    const [isNativeEdit, setIsNativeEdit] = useState();
    const [isLearningEdit, setIsLearningEdit] = useState();
    const [userNativeLanguage, setUserNativeLanguage] = useState();
    const [updateError, setUpdateError] = useState();
    const navigate = useNavigate();
    const [userState, setUserState] = useOutletContext();
    const [userTargetLanguages, setUserTargetLanguage] = useState([]);
    let languageNames = new Intl.DisplayNames(["en"], { type: "language" });


    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if(user) {
                fetchUserSettings(user);
            }
        })
    }, []);

    async function fetchUserSettings(user) {
        setIsLoading(true);
        try {
            const userSettingsQuery = query(collection(db, "userSettings"), where("userID", "==", user?.uid));
            const querySnapshot = await getDocs(userSettingsQuery);
            let newData = [];
            querySnapshot.forEach((doc) => {
                newData.push({id: doc.id, ...doc.data()});
            })
            setUserSettings(newData);
        } catch(e) {
            console.log(e);
        }
        setIsLoading(false);
    }

    function handleDeleteOpen() {
        setIsDeleting(true);
    }

    function handleDeleteClose() {
        setIsDeleting(false);
    }

    function handleNativeEditOpen() {
        setIsNativeEdit(true);
    }

    function handleNativeEditClose() {
        setIsNativeEdit(false);
    }

    function handleLearningEditOpen() {
        setIsLearningEdit(true);
    }

    function handleLearningEditClose() {
        setIsLearningEdit(false);
    }

    function handleUserNativeLanguageChange(e) {
        setUserNativeLanguage(e.target.value);
    }

    async function handleNativeEditUpdate() {
        const auth = getAuth();
        const user = auth.currentUser;
        try {
            const nativeRef = doc(db, "userSettings", userSettings[0].id);
            await updateDoc(nativeRef, {
                nativeLanguage: userNativeLanguage
            });
            setIsNativeEdit(false);
            fetchUserSettings(user);
            setUpdateError(<Error messageType="success" errorMessage={`Successfully updated your native language`} />)
        } catch(error) {
            setUpdateError(<Error messageType="error" errorMessage={`Error updating your native language. Please try again. ${error}`} />)
        }
    }

    async function handleLearningEditUpdate() {
        const auth = getAuth();
        const user = auth.currentUser;
        try {
            const nativeRef = doc(db, "userSettings", userSettings[0].id);
            await updateDoc(nativeRef, {
                targetLanguages: userTargetLanguages
            });
            setIsLearningEdit(false);
            fetchUserSettings(user);
            setUpdateError(<Error messageType="success" errorMessage={`Successfully updated the languages you are learning`} />)
        } catch(error) {
            setUpdateError(<Error messageType="error" errorMessage={`Error updating your learning languages. Please try again.`} />)
        }
    }

    function handleDeleteAccount() {
        const auth = getAuth();
        const user = auth.currentUser;
        deleteUser(user).then(() => {
            navigate("/");
            setUserState(null);
          }).catch((error) => {
            setError(<Error messageType="error" errorMessage="An error occured. Please try again." />)
            console.log(error);
        });
    }


    function handleUserTargetLanguageChange(e) {
        const {
            target: { value },
          } = e;
          setUserTargetLanguage(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
          );
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
    PaperProps: {
        style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        },
    },
    };


    return (
        <div className="account-container">
            <div className="sidebar">
                <AccountSidebar />
            </div>
            <div className="account-settings" style={{marginTop: "20px"}}>
                {updateError ? updateError : ""}
                <Grid container spacing={2}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={5}>
                        <Card>
                            <CardContent sx={{bgcolor: "#cfd8db"}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={9}>
                                        <Typography variant="h5">
                                            <strong>I Speak</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button variant="outline" startIcon={<EditIcon />} onClick={handleNativeEditOpen}>Edit</Button>
                                    </Grid>
                                </Grid>
                                
                                <Typography variant="body2">
                                    {userSettings.length !== 0 ? 
                                    <Box sx={{padding: "10px"}}>
                                        <span style={{fontSize: "24px"}}>{languageNames.of(userSettings[0].nativeLanguage) }</span>
                                    </Box>
                                    : 
                                    <CircularProgress />}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={5}>
                    <Card>
                        <CardContent sx={{bgcolor: "#cfd8db"}}>
                            <Grid container spacing={2}>
                                <Grid item xs={9}>
                                    <Typography variant="h5">
                                        <strong>I'm Learning</strong>
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button variant="outline" startIcon={<EditIcon />} onClick={handleLearningEditOpen}>Edit</Button>
                                </Grid>
                            </Grid>
                            
                            <Typography variant="body2">
                                {userSettings.length === 0 ? <CircularProgress /> : ""}
                                {userSettings[0]?.targetLanguages.map((language, i) => {
                                    return <>
                                        <Box sx={{display: "flex", gap: "10px", padding: "10px"}}>
                                            <li key={i} style={{fontSize: "20px"}}>{languageNames.of(language)}</li>
                                        </Box>
                                    </>
                                })}
                            </Typography>
                        </CardContent>
                    </Card>
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
                <Button variant="outlined" color="error" onClick={handleDeleteOpen}>Delete Account</Button>
                <Dialog
                    open={isDeleting}
                    onClose={handleDeleteClose}
                    aria-labelledby="delete-account"
                    aria-describedby="deleting-account"
                    >
                    <DialogTitle id="delete-account-title">
                    {error ? error : ""}
                    {"Are you sure you want to delete your account?"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="delete-account-description">
                        This will completely delete your account along with any data along with it. This cannot be undone.
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleDeleteClose}>Cancel</Button>
                    <Button color="error" onClick={handleDeleteAccount}>Yes, Delete My Account</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={isNativeEdit}
                    onClose={handleNativeEditClose}
                    aria-labelledby="edit-native"
                    aria-describedby="edit-native"
                    >
                    <DialogTitle id="edit-native-title">
                    {error ? error : ""}
                    {"What Language Do You Speak Now?"}
                    </DialogTitle>
                    <DialogContent>
                        <Select
                            labelId="native-language-select-label"
                            id="native-language-select"
                            value={userNativeLanguage}
                            onChange={handleUserNativeLanguageChange}
                            sx={{width: "70%", margin: "0 auto"}}
                            required
                        >
                            <MenuItem value={"en"}>English</MenuItem>
                            <MenuItem value={"es"}>Spanish</MenuItem>
                            <MenuItem value={"fr"}>French</MenuItem>
                            <MenuItem value={"de"}>German</MenuItem>
                            <MenuItem value={"zh"}>Chinese</MenuItem>
                            <MenuItem value={"ar"}>Arabic</MenuItem>
                            <MenuItem value={"pt"}>Portuguese</MenuItem>
                        </Select>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleNativeEditClose}>Cancel</Button>
                    <Button variant="outlined" color="success" onClick={handleNativeEditUpdate}>Update</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={isLearningEdit}
                    onClose={handleLearningEditClose}
                    aria-labelledby="edit-learning"
                    aria-describedby="edit-learning"
                    >
                    <DialogTitle id="edit-learning-title">
                    {error ? error : ""}
                    {"What Languages Would you Like to Learn?"}
                    </DialogTitle>
                    <DialogContent>
                        <Select
                        labelId="target-language-select-label"
                        id="target-language-select"
                        multiple
                        sx={{width: "70%", margin: "0 auto"}}
                        value={userTargetLanguages}
                        required
                        onChange={handleUserTargetLanguageChange}
                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={languageNames.of(value)} />
                            ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                        >
                            <MenuItem value={"en"}>English</MenuItem>
                            <MenuItem value={"es"}>Spanish</MenuItem>
                            <MenuItem value={"fr"}>French</MenuItem>
                            <MenuItem value={"de"}>German</MenuItem>
                            <MenuItem value={"zh"}>Chinese</MenuItem>
                            <MenuItem value={"ar"}>Arabic</MenuItem>
                            <MenuItem value={"pt"}>Portuguese</MenuItem>
                        </Select>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleLearningEditClose}>Cancel</Button>
                    <Button variant="outlined" color="success" onClick={handleLearningEditUpdate}>Update</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
}