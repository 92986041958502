import Navbar from "./components/Main App Components/Navbar";
import { Outlet } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { app } from "./FirebaseConfig";

function App() {
  const [user, setUser] = useState();

const auth = getAuth();
useEffect(() => {
  onAuthStateChanged(auth, (user) => {
    if(user) {
      setUser(user);
    } else {
    }
  })
})

  return (
    <>
      <Navbar user={user} setUser={setUser} />
      <div id="detail">
        <Outlet context={[user, setUser]} />
      </div>
    </>
  );
}

export default App;
