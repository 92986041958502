import {useState} from "react";
import { ThreeDots } from "react-loading-icons";

export default function GenerateButton({ isLoading }) {

    return (
        <button type="submit" 
            className="language-input-button">
            {isLoading ? <div className="loading-icon"><ThreeDots className="threeDot-loader" fill="#06bcee" /></div> : "Create"}
        </button>
    );
}