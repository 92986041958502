import { Chip, Tooltip, tooltipClasses } from "@mui/material";
import { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';


export default function WordForm({ wordValue, wordId, autoGrading, wordBankWords, inputRefs, wordChoice }) {
    const [wordIsCorrect, setWordIsCorrect] = useState();
    const [wordOptions, setWordOptions] = useState([]);

    useEffect(() => {
        setWordOptions(shuffleWordBank(wordBankWords));
    }, [wordBankWords])

    function handleChange(e) {
        if(autoGrading) {
            if(e.target.value === wordValue) {
                setWordIsCorrect(true);
            } else {
                setWordIsCorrect(false);
            }
        } 
    }

    function shuffleWordBank(tempCorrectWords) {        
        for (let i = tempCorrectWords.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = tempCorrectWords[i];
            tempCorrectWords[i] = tempCorrectWords[j];
            tempCorrectWords[j] = temp;
        }
        
        return tempCorrectWords;
    }

    const StyledTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: '#f5f5f9',
          color: 'rgba(0, 0, 0, 0.87)',
          fontSize: theme.typography.pxToRem(12),
          whiteSpace: "nowrap",
          maxWidth: "none",
          border: '1px solid #dadde9',
        },
      }));

    return (
        <div className="word-form-container">
            {wordChoice ? 
            <StyledTooltip placement="top" title={
                <div>
                    <Chip label={wordOptions[0]} variant="outlined" sx={{margin: "0 2px", backgroundColor: "white"}} />
                    <Chip label={wordOptions[1]} variant="outlined" sx={{margin: "0 2px", backgroundColor: "white"}} />
                    <Chip label={wordOptions[2]} variant="outlined" sx={{margin: "0 2px", backgroundColor: "white"}} />
                    <Chip label={wordOptions[3]} variant="outlined" sx={{margin: "0 2px", backgroundColor: "white"}} />
                    <Chip label={wordOptions[4]} variant="outlined" sx={{margin: "0 2px", backgroundColor: "white"}} />
                </div>
            }>
            <input 
                type="text" 
                id={wordId} 
                ref={inputRefs} 
                className={`word-form ${autoGrading ? wordIsCorrect ? "correct-word" : "incorrect-word" : ""}`} 
                onChange={handleChange}
            />
            </StyledTooltip>
            :
            <input 
                type="text" 
                id={wordId} 
                ref={inputRefs} 
                className={`word-form ${autoGrading ? wordIsCorrect ? "correct-word" : "incorrect-word" : ""}`} 
                onChange={handleChange}
            />
        }
        </div>
    );
}