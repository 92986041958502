import LanguageInput from "./LanguageInput";
import TestPane from "./TestPane";
import {useState} from "react";
import YoutubeInput from "./YoutubeInput";
import YoutubePlayer from "./YoutubePlayer";

export default function InputContainer() {
    const [examInput, setExamInput] = useState([]);
    const [autoGrading, setAutoGrading] = useState(false);
    const [isSelectedGenerate, setIsSelectedGenerate] = useState(false);
    const [fullYoutubeCaptionObject, setFullYoutubeCaptionObject] = useState(false);
    const [inputType, setInputType] = useState(true);
    const [amountOfTestWords, setAmountOfTestWords] = useState(0);
    const [correctWords, setCorrectWords] = useState([]);
    const [youtubeURL, setYoutubeURL] = useState("");
    const [isTextInput, setIsTextInput] = useState();
    const [wordChoice, setWordChoice] = useState(true);
    const [translationData, setTranslationData] = useState([]);
    const [isTranslationLoading, setIsTranslationLoading] = useState(false);
    const [savedWords, setSavedWords] = useState([]);

    console.log(fullYoutubeCaptionObject);
    function toggleInputText() {
        setInputType(false);
    }

    function toggleInputYoutube() {
        setInputType(true);
    }

    return (
        <>
            <div className="input-container-wrapper" style={inputType ? {gridTemplateRows: '280px 1fr'} : {gridTemplateRows: '380px 1fr'}}>
                <div className="language-input-container">
                    <div className="choose-input-type">
                        <button className="input-type-button" onClick={toggleInputYoutube}>YouTube</button><button className="input-type-button" onClick={toggleInputText}>Copy & Paste Text</button>
                    </div>
                    {inputType ?  
                        <YoutubeInput 
                            setFullYoutubeCaptionObject={setFullYoutubeCaptionObject} 
                            setExamInput={setExamInput} 
                            setIsSelectedGenerate={setIsSelectedGenerate} 
                            setAmountOfTestWords={setAmountOfTestWords}
                            youtubeURL={youtubeURL}
                            setYoutubeURL={setYoutubeURL}
                            autoGrading={autoGrading}
                            setAutoGrading={setAutoGrading}
                            setIsTextInput={setIsTextInput}
                            setWordChoice={setWordChoice}
                            wordChoice={wordChoice}
                            />
                        :
                        <LanguageInput 
                            setExamInput={setExamInput} 
                            examInput={examInput} 
                            autoGrading={autoGrading} 
                            setAutoGrading={setAutoGrading} 
                            isSelectedGenerate={isSelectedGenerate}
                            setIsSelectedGenerate={setIsSelectedGenerate}
                            setAmountOfTestWords={setAmountOfTestWords}
                            setIsTextInput={setIsTextInput}
                            setWordChoice={setWordChoice}
                            wordChoice={wordChoice}
                        />
                    }   
                </div>
                <div className="test-pane-container">
                    {isSelectedGenerate ? <TestPane 
                        examInput={examInput} 
                        setAutoGrading={setAutoGrading} 
                        autoGrading={autoGrading} 
                        amountOfTestWords={amountOfTestWords}
                        correctWords={correctWords}
                        setCorrectWords={setCorrectWords}
                        wordChoice={wordChoice}
                        setWordChoice={setWordChoice}
                        youtubeURL={youtubeURL}
                        setTranslationLoading={setIsTranslationLoading}
                        setTranslationData={setTranslationData}
                        setSavedWords={setSavedWords}
                        savedWords={savedWords}
                        translationData={translationData}
                        fullYoutubeCaptionObject={fullYoutubeCaptionObject}
                    /> : ""}
                </div>
                <div className="word-bank-container">
                    
                </div>
                <div className="youtube-video-component">
                    {(isSelectedGenerate) ? <YoutubePlayer 
                        videoURL={youtubeURL}
                    /> : ""}
                </div>
            </div>
        </>
    );
}