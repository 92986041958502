import "../../assets/YoutubeTesterStyles.css";

export default function YoutubeTesterWordDisplay({ videoCounter, youtubeWordObject }) {

        return (
           <div className="tester-word-display">
                {youtubeWordObject[videoCounter].map((wordObject, i) => {
                    return (
                        wordObject.isTestWord ? <span className="tester-blanks"><span className="display-blank-word">test word test</span></span> : <span> {wordObject.word} </span>
                    )
                })}
           </div>
        );
}