import "../../assets/AccountStyles.css";
import AccountSidebar from "../AccountComponents/AccountSidebar";
import { useState, useEffect } from "react";
import { collection, getDocs, where, query } from "firebase/firestore"; 
import { db } from "../../FirebaseConfig";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import SavedWordsCard from "./SavedWordsCard";
import { Button, ButtonGroup, CircularProgress, Grid, Paper } from "@mui/material";


export default function SavedWordsInterface() {
    const [savedWords, setSavedWords] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if(user) {
                fetchSavedWords(user);
            }
        })
    }, []);
    
    async function fetchSavedWords(user) {
        setIsLoading(true);
        try {
            const savedWordsQuery = query(collection(db, "savedWords"), where("userID", "==", user?.uid));
            const querySnapshot = await getDocs(savedWordsQuery);
            let newData = [];
            querySnapshot.forEach((doc) => {
                newData.push({id: doc.id, ...doc.data()});
            })
            setSavedWords(newData);
        } catch(e) {
            console.log(e);
        }
        setIsLoading(false);
    }

    function handleFilter(filterCondition) {
        const auth = getAuth();

        if(filterCondition === "favorites") {
            let filteredSavedWords = savedWords.filter(card => card.isFavorite === true);
            console.log(filteredSavedWords);
            setSavedWords(filteredSavedWords);
        }
        if(filterCondition === "newest") {
            onAuthStateChanged(auth, (user) => {
                if(user) {
                    fetchSavedWords(user);
                }
            })
        }
    }

    return (
        <div className="account-container">
            <div className="sidebar">
                <AccountSidebar />
            </div>
            <div className="saved-words-interface">
                <div className="saved-words-filter">
                    <Grid container spacing={2} sx={{marginTop: "20px"}}>
                        <Grid item xs={8}>

                        </Grid>
                        <Grid item xs={4}>
                            <ButtonGroup variant="outlined" aria-label="list filter">
                                <Button onClick={() => handleFilter("newest")}>Newest</Button>
                                <Button onClick={() => handleFilter("favorites")}>Favorites</Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </div>
                {isLoading ? <CircularProgress /> :
                <div className="saved-words-list">
                      
                    {savedWords.length !== 0 ? 
                        savedWords.map((element, i) => {
                            return <SavedWordsCard 
                                        key={i} 
                                        id={element.id}
                                        videoID={element.videoID} 
                                        dateSaved={element.dateSaved} 
                                        words={element.words} 
                                        isFavorite={element.isFavorite} 
                                        fetchSavedWords={fetchSavedWords}
                                    />
                    })
                    : <p>No Data</p>
                    }
                </div>
                }   
            </div>
        </div>
    );
}