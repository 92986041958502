import { getSubtitles } from "youtube-captions-scraper";
import { useState } from "react";
import GenerateButton from "./GenerateButton";
import axios from "axios";
import AdvancedOptions from "./AdvancedOptions";
import { Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import TuneIcon from '@mui/icons-material/Tune';
import Error from "./Error";


export default function YoutubeInput({ setTextInput, 
        setExamInput, 
        setFullYoutubeCaptionObject, 
        setIsSelectedGenerate, 
        setAmountOfTestWords, 
        youtubeURL, 
        setYoutubeURL, 
        autoGrading, 
        setAutoGrading,
        wordChoice,
        setWordChoice
    }) {
    const [targetLanguage, setTargetLanguage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [testFrequency, setTestFrequency] = useState(0.02);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState();

    function handleURLChange(e) {
        setYoutubeURL(e.target.value);
    }

    function handleLanguageSelectChange(e) {
        setTargetLanguage(e.target.value);
    }

    function handleWordChoiceClick() {
        setWordChoice(!wordChoice);
    }

    function handleGradingClick() {
        setAutoGrading(!autoGrading);
    }

    function handleClickOpen() {
        setOpen(true);
    }

    function handleYoutubeSubmit(e) {
        e.preventDefault();
        setIsLoading(true);
        if(youtubeURL.length > 29 && testFrequency > 0) {
            let tempURL = youtubeURL;
            let videoID = tempURL.substring(tempURL.indexOf('=') + 1, tempURL.length);
            
            axios.get(`https://us-central1-active-languages-real-23eu2.cloudfunctions.net/getCaptions`, {
                params: {
                    youtubeID: videoID,
                    language: targetLanguage
                }
            })
            .then(captions => {
                let youtubeFullString = "";
                let youtubeStringArray = [];
                let uniqueNumberList = [];

                //only get the text from the captions
                for(let i = 0; i < captions.data.length; i++) {
                    youtubeFullString += captions.data[i].text + " ";
                }

                //remove the \n all over the captions
                youtubeFullString.replace("\n", "");
                //split into array
                youtubeStringArray = youtubeFullString.split(' ');
                let processedInput = [];
                //create array of word objects
                for(let i = 0; i < youtubeStringArray.length; i++) {
                    processedInput.push({"id": i, "word": youtubeStringArray[i], "isTestWord": false, "isCorrect": "", "userInputValue": ""});
                }

                //set Test Frequency
                const numberOfTestWords = Math.floor(processedInput.length * testFrequency);
                setAmountOfTestWords(numberOfTestWords);

                //loop through to create test words
                for(let i = 0; i < numberOfTestWords; i++) {
                    const randIndex = Math.floor(Math.random() * processedInput.length);
                    //check if the word has already been used or if it contains any punctuation
                    if(!uniqueNumberList.includes(randIndex) && /[,.?]/.test(processedInput[randIndex].word) === false) {
                        processedInput[randIndex].isTestWord = true;
                        uniqueNumberList.push(randIndex);
                    } else {
                        i--;
                    }
                }

                setIsLoading(false);
                setFullYoutubeCaptionObject(captions.data);
                setExamInput(processedInput);
                setIsSelectedGenerate(true);
            }).catch(error => {
                setError(<Error messageType={"error"} errorMessage={`Please try again. Also ensure that the video has subtitles, if it does not an error will occur. Error message: ${error}`} />);
            })
        } else {
            setError(<Error messageType={"warning"} errorMessage={"Please enter a valid YouTube Link"} />);
        }
    }


    const languageSelectDescription = `
        Select the language you would like the text to appear in. This would be considered your target language.
        For example, if you would like to study the words in Spanish, choose Spanish.
    `;

    return (
        <div className="youtube-input-form">
            {error ? error : ""}
            <form onSubmit={handleYoutubeSubmit}>
                <label className="youtubeURLLabel" htmlFor="urlForm">Enter Youtube URL: </label>
                <input type="text" className="youtubeURLForm" id="urlForm" onChange={handleURLChange} required />
                <select className="youtubeLanguageSelect" onChange={handleLanguageSelectChange} style={{margin: "5px", padding: "1px", display: "inline"}}>
                    <option value="">Select Language</option>
                    <option value="en">English</option>
                    <option value="es">Spanish</option>
                    <option value="fr">French</option>
                    <option value="de">German</option>
                    <option value="zh">Chinese</option>
                    <option value="pt">Portuguese</option>
                </select>
                <Tooltip title={languageSelectDescription} style={{padding: "3px", verticalAlign: "text-top", fontSize: "18px"}}><InfoIcon></InfoIcon></Tooltip>
                <button type="button" className="configure-options-button" onClick={handleClickOpen}><TuneIcon style={{fontSize: "1rem", paddingRight: "3px", verticalAlign: "bottom"}}></TuneIcon>Configure Options</button>
                <AdvancedOptions open={open} setOpen={setOpen} testFrequency={testFrequency} setTestFrequency={setTestFrequency} handleGradingClick={handleGradingClick} handleWordChoiceClick={handleWordChoiceClick} />
                <GenerateButton isLoading={isLoading} setIsLoading={setIsLoading} />
            </form>
        </div>
    );
}